import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import Table from '../Table'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'
import { ANTIGEN_LOOKUP_API } from '../../utils/const'

const SP41Table = ({ regions }) => {
  const { t } = useTranslation()
  // const wrangleSP41Data = (data) => {
  //   const allAntigens = []
  //   const allData = Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const year = +key.slice(-4)
  //         let antigen = key.slice(0, -4)
  //         if (antigen === 'bOP') antigen = 'Mean'
  //         allAntigens.push(antigen)
  //         allYears.push(year)
  //         return { year, antigen, value: value * 100 }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   const antigens = Array.from(new Set(allAntigens)).sort((a, b) =>
  //     a === 'Mean' ? -1 : 1
  //   )

  //   const formattedData = antigens.map((antigen) => {
  //     const antigenData = allData
  //       .filter((d) => d.antigen === antigen)
  //       .reduce((acc, d) => {
  //         acc[d.year] =
  //           d.value >= 0
  //             ? d.value.toFixed() + '%'
  //             : d.value === -2
  //             ? t('no data')
  //             : t('not measured')
  //         return acc
  //       }, {})
  //     return Object.assign(
  //       {
  //         antigen: antigen === 'Mean' ? t('Mean') : t(antigen.toUpperCase())
  //       },
  //       antigenData
  //     )
  //   })
  //   return formattedData
  // }
  const yrs = []
  const BASELINE_YEAR = 2019
  const wrangleSP41Data = (data) => {
    data.forEach((d) => {
      if (d.YEAR !== BASELINE_YEAR) yrs.push(d.YEAR)
      //preserves property names from original code
      if (d.DIMENSION2 === null) d.antigen = 'BOP'
      // else if (d.DIMENSION2 === 'PRHPVC_F') d.antigen = 'HPVc'
      // else if (d.DIMENSION2 === 'DTPCV3') d.antigen = 'DTP3'
      else d.antigen = d.DIMENSION2
    })
    const dataMap = group(data, (d) => d.DIMENSION2)
    const arr = Array.from(dataMap.values())
      .sort((a, b) =>
        ANTIGEN_LOOKUP_API[a[0].antigen] > ANTIGEN_LOOKUP_API[b[0].antigen]
          ? 1
          : -1
      )
      .sort((a, b) => (a[0].antigen === 'BOP' ? -1 : 1))

    return arr.map((a) =>
      a.reduce((acc, d) => {
        acc[d.YEAR === BASELINE_YEAR ? 'Baseline' : d.YEAR] =
          d.VALUE_NUM === null ? t('no data') : d.VALUE_NUM.toFixed() + '%'
        acc['antigen'] = t(`${ANTIGEN_LOOKUP_API[d.antigen]}-SP41`)
        return acc
      }, {})
    )
  }
  const sp41Data = wrangleSP41Data(regions)

  const data = React.useMemo(() => sp41Data, [sp41Data])
  const yearsWithData = Array.from(new Set(yrs)).sort((a, b) =>
    a > b ? 1 : -1
  )

  const yearHeaders = yearsWithData.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('antigen'),
        accessor: 'antigen' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  return (
    <Table
      data={data}
      columns={columns}
      description={t(
        'A table that lists the percentage of protection of 12 antigens - by year'
      )}
    />
  )
}

export default SP41Table
