import * as React from 'react'
import PageHeader from '../components/PageHeader'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import SP41Chart from '../components/SP41/SP41Chart'
import SP41Table from '../components/SP41/SP41Table'
import Footer from '../components/Footer'
import {
  LOCATION_LABELS,
  INCOME_GROUPS,
  UNI_REGIONS,
  WHO_LOCATION_OPTIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
//import InsufficientData from '../components/InsufficientData'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'
import ImageExport from '../components/ImageExport'

const Sp41Page = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { sectionId } = pageContext
  const { content, footerContent } = data

  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  const isInc = INCOME_GROUPS.includes(reg)
  const isUni = UNI_REGIONS.includes(reg)
  const isGbl = reg === 'GBL' || !reg
  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])
  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS}
          uniOptions={UNI_LOCATION_OPTIONS}
        />
        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>

        <ImageExport
          title={`${content.chartTitle}—${regionLabel}`}
          dataSource={content.dataSourceNotesRt}
        >
          <SP41Chart
            regions={data.region.nodes.filter(
              (d) => d.CODE === REGION_LOOKUP[reg]
            )}
            title={`${content.chartTitle}—${regionLabel}`}
          />
        </ImageExport>
        <SP41Chart
          regions={data.region.nodes.filter(
            (t) => t.CODE === REGION_LOOKUP[reg]
          )}
          title={`${content.chartTitle}—${regionLabel}`}
          //content={content}
        />
        <SP41Table
          regions={data.region.nodes.filter(
            (t) => t.CODE === REGION_LOOKUP[reg]
          )}
        />

        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Sp41Page

// iso3 is null for global page but this works because it is the only null one
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleSp41CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    region: allRegions(filter: { IA2030_IND: { eq: "SP4.1" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        VALUE_NUM
        YEAR
      }
    }
  }
`
// global: googleSp41Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   region
//   memberState
//   bOP2019
//   bOP2020
//   bOP2021
//   bOP2022
//   bOP2023
//   bOP2024
//   bOP2025
//   bOP2026
//   bOP2027
//   bOP2028
//   bOP2029
//   bOP2030

//   dtp32019
//   dtp32020
//   dtp32021
//   dtp32022
//   dtp32023
//   dtp32024
//   dtp32025
//   dtp32026
//   dtp32027
//   dtp32028
//   dtp32029
//   dtp32030

//   hPV2019
//   hPV2020
//   hPV2021
//   hPV2022
//   hPV2023
//   hPV2024
//   hPV2025
//   hPV2026
//   hPV2027
//   hPV2028
//   hPV2029
//   hPV2030

//   hepb32019
//   hepb32020
//   hepb32021
//   hepb32022
//   hepb32023
//   hepb32024
//   hepb32025
//   hepb32026
//   hepb32027
//   hepb32028
//   hepb32029
//   hepb32030

//   hib32019
//   hib32020
//   hib32021
//   hib32022
//   hib32023
//   hib32024
//   hib32025
//   hib32026
//   hib32027
//   hib32028
//   hib32029
//   hib32030

//   ipv12019
//   ipv12020
//   ipv12021
//   ipv12022
//   ipv12023
//   ipv12024
//   ipv12025
//   ipv12026
//   ipv12027
//   ipv12028
//   ipv12029
//   ipv12030

//   mcv12019
//   mcv12020
//   mcv12021
//   mcv12022
//   mcv12023
//   mcv12024
//   mcv12025
//   mcv12026
//   mcv12027
//   mcv12028
//   mcv12029
//   mcv12030

//   mcv22019
//   mcv22020
//   mcv22021
//   mcv22022
//   mcv22023
//   mcv22024
//   mcv22025
//   mcv22026
//   mcv22027
//   mcv22028
//   mcv22029
//   mcv22030

//   pcv32019
//   pcv32020
//   pcv32021
//   pcv32022
//   pcv32023
//   pcv32024
//   pcv32025
//   pcv32026
//   pcv32027
//   pcv32028
//   pcv32029
//   pcv32030

//   pol32019
//   pol32020
//   pol32021
//   pol32022
//   pol32023
//   pol32024
//   pol32025
//   pol32026
//   pol32027
//   pol32028
//   pol32029
//   pol32030

//   rcv12019
//   rcv12020
//   rcv12021
//   rcv12022
//   rcv12023
//   rcv12024
//   rcv12025
//   rcv12026
//   rcv12027
//   rcv12028
//   rcv12029
//   rcv12030

//   rotac2019
//   rotac2020
//   rotac2021
//   rotac2022
//   rotac2023
//   rotac2024
//   rotac2025
//   rotac2026
//   rotac2027
//   rotac2028
//   rotac2029
//   rotac2030
// }
// regions: allGoogleSp41Sheet(filter: { regionType: { eq: "REG" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     bOP2019
//     bOP2020
//     bOP2021
//     bOP2022
//     bOP2023
//     bOP2024
//     bOP2025
//     bOP2026
//     bOP2027
//     bOP2028
//     bOP2029
//     bOP2030

//     dtp32019
//     dtp32020
//     dtp32021
//     dtp32022
//     dtp32023
//     dtp32024
//     dtp32025
//     dtp32026
//     dtp32027
//     dtp32028
//     dtp32029
//     dtp32030

//     hPV2019
//     hPV2020
//     hPV2021
//     hPV2022
//     hPV2023
//     hPV2024
//     hPV2025
//     hPV2026
//     hPV2027
//     hPV2028
//     hPV2029
//     hPV2030

//     hepb32019
//     hepb32020
//     hepb32021
//     hepb32022
//     hepb32023
//     hepb32024
//     hepb32025
//     hepb32026
//     hepb32027
//     hepb32028
//     hepb32029
//     hepb32030

//     hib32019
//     hib32020
//     hib32021
//     hib32022
//     hib32023
//     hib32024
//     hib32025
//     hib32026
//     hib32027
//     hib32028
//     hib32029
//     hib32030

//     ipv12019
//     ipv12020
//     ipv12021
//     ipv12022
//     ipv12023
//     ipv12024
//     ipv12025
//     ipv12026
//     ipv12027
//     ipv12028
//     ipv12029
//     ipv12030

//     mcv12019
//     mcv12020
//     mcv12021
//     mcv12022
//     mcv12023
//     mcv12024
//     mcv12025
//     mcv12026
//     mcv12027
//     mcv12028
//     mcv12029
//     mcv12030

//     mcv22019
//     mcv22020
//     mcv22021
//     mcv22022
//     mcv22023
//     mcv22024
//     mcv22025
//     mcv22026
//     mcv22027
//     mcv22028
//     mcv22029
//     mcv22030

//     pcv32019
//     pcv32020
//     pcv32021
//     pcv32022
//     pcv32023
//     pcv32024
//     pcv32025
//     pcv32026
//     pcv32027
//     pcv32028
//     pcv32029
//     pcv32030

//     pol32019
//     pol32020
//     pol32021
//     pol32022
//     pol32023
//     pol32024
//     pol32025
//     pol32026
//     pol32027
//     pol32028
//     pol32029
//     pol32030

//     rcv12019
//     rcv12020
//     rcv12021
//     rcv12022
//     rcv12023
//     rcv12024
//     rcv12025
//     rcv12026
//     rcv12027
//     rcv12028
//     rcv12029
//     rcv12030

//     rotac2019
//     rotac2020
//     rotac2021
//     rotac2022
//     rotac2023
//     rotac2024
//     rotac2025
//     rotac2026
//     rotac2027
//     rotac2028
//     rotac2029
//     rotac2030
//   }
// }
// income: allGoogleSp41Sheet(filter: { regionType: { eq: "INC" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     incomeGroup
//     bOP2019
//     bOP2020
//     bOP2021
//     bOP2022
//     bOP2023
//     bOP2024
//     bOP2025
//     bOP2026
//     bOP2027
//     bOP2028
//     bOP2029
//     bOP2030

//     dtp32019
//     dtp32020
//     dtp32021
//     dtp32022
//     dtp32023
//     dtp32024
//     dtp32025
//     dtp32026
//     dtp32027
//     dtp32028
//     dtp32029
//     dtp32030

//     hPV2019
//     hPV2020
//     hPV2021
//     hPV2022
//     hPV2023
//     hPV2024
//     hPV2025
//     hPV2026
//     hPV2027
//     hPV2028
//     hPV2029
//     hPV2030

//     hepb32019
//     hepb32020
//     hepb32021
//     hepb32022
//     hepb32023
//     hepb32024
//     hepb32025
//     hepb32026
//     hepb32027
//     hepb32028
//     hepb32029
//     hepb32030

//     hib32019
//     hib32020
//     hib32021
//     hib32022
//     hib32023
//     hib32024
//     hib32025
//     hib32026
//     hib32027
//     hib32028
//     hib32029
//     hib32030

//     ipv12019
//     ipv12020
//     ipv12021
//     ipv12022
//     ipv12023
//     ipv12024
//     ipv12025
//     ipv12026
//     ipv12027
//     ipv12028
//     ipv12029
//     ipv12030

//     mcv12019
//     mcv12020
//     mcv12021
//     mcv12022
//     mcv12023
//     mcv12024
//     mcv12025
//     mcv12026
//     mcv12027
//     mcv12028
//     mcv12029
//     mcv12030

//     mcv22019
//     mcv22020
//     mcv22021
//     mcv22022
//     mcv22023
//     mcv22024
//     mcv22025
//     mcv22026
//     mcv22027
//     mcv22028
//     mcv22029
//     mcv22030

//     pcv32019
//     pcv32020
//     pcv32021
//     pcv32022
//     pcv32023
//     pcv32024
//     pcv32025
//     pcv32026
//     pcv32027
//     pcv32028
//     pcv32029
//     pcv32030

//     pol32019
//     pol32020
//     pol32021
//     pol32022
//     pol32023
//     pol32024
//     pol32025
//     pol32026
//     pol32027
//     pol32028
//     pol32029
//     pol32030

//     rcv12019
//     rcv12020
//     rcv12021
//     rcv12022
//     rcv12023
//     rcv12024
//     rcv12025
//     rcv12026
//     rcv12027
//     rcv12028
//     rcv12029
//     rcv12030

//     rotac2019
//     rotac2020
//     rotac2021
//     rotac2022
//     rotac2023
//     rotac2024
//     rotac2025
//     rotac2026
//     rotac2027
//     rotac2028
//     rotac2029
//     rotac2030
//   }
// }
// unicef: allGoogleSp41Sheet(filter: { regionType: { eq: "UNI" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     uNICEFRegion
//     bOP2019
//     bOP2020
//     bOP2021
//     bOP2022
//     bOP2023
//     bOP2024
//     bOP2025
//     bOP2026
//     bOP2027
//     bOP2028
//     bOP2029
//     bOP2030

//     dtp32019
//     dtp32020
//     dtp32021
//     dtp32022
//     dtp32023
//     dtp32024
//     dtp32025
//     dtp32026
//     dtp32027
//     dtp32028
//     dtp32029
//     dtp32030

//     hPV2019
//     hPV2020
//     hPV2021
//     hPV2022
//     hPV2023
//     hPV2024
//     hPV2025
//     hPV2026
//     hPV2027
//     hPV2028
//     hPV2029
//     hPV2030

//     hepb32019
//     hepb32020
//     hepb32021
//     hepb32022
//     hepb32023
//     hepb32024
//     hepb32025
//     hepb32026
//     hepb32027
//     hepb32028
//     hepb32029
//     hepb32030

//     hib32019
//     hib32020
//     hib32021
//     hib32022
//     hib32023
//     hib32024
//     hib32025
//     hib32026
//     hib32027
//     hib32028
//     hib32029
//     hib32030

//     ipv12019
//     ipv12020
//     ipv12021
//     ipv12022
//     ipv12023
//     ipv12024
//     ipv12025
//     ipv12026
//     ipv12027
//     ipv12028
//     ipv12029
//     ipv12030

//     mcv12019
//     mcv12020
//     mcv12021
//     mcv12022
//     mcv12023
//     mcv12024
//     mcv12025
//     mcv12026
//     mcv12027
//     mcv12028
//     mcv12029
//     mcv12030

//     mcv22019
//     mcv22020
//     mcv22021
//     mcv22022
//     mcv22023
//     mcv22024
//     mcv22025
//     mcv22026
//     mcv22027
//     mcv22028
//     mcv22029
//     mcv22030

//     pcv32019
//     pcv32020
//     pcv32021
//     pcv32022
//     pcv32023
//     pcv32024
//     pcv32025
//     pcv32026
//     pcv32027
//     pcv32028
//     pcv32029
//     pcv32030

//     pol32019
//     pol32020
//     pol32021
//     pol32022
//     pol32023
//     pol32024
//     pol32025
//     pol32026
//     pol32027
//     pol32028
//     pol32029
//     pol32030

//     rcv12019
//     rcv12020
//     rcv12021
//     rcv12022
//     rcv12023
//     rcv12024
//     rcv12025
//     rcv12026
//     rcv12027
//     rcv12028
//     rcv12029
//     rcv12030

//     rotac2019
//     rotac2020
//     rotac2021
//     rotac2022
//     rotac2023
//     rotac2024
//     rotac2025
//     rotac2026
//     rotac2027
//     rotac2028
//     rotac2029
//     rotac2030
//   }
// }
